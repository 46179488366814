import React from "react";
// Customizable Area Start
import {
    TextField,
    FormControl,
    Button,
    Grid,
    Box,
    Typography,
    ThemeProvider,
    InputAdornment,
    IconButton,
    styled,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    FormHelperText,
    Snackbar,
    Alert
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
  
  import * as Yup from "yup";
  import { Formik, Form, Field } from 'formik';
  import Visibility from '@mui/icons-material/Visibility';
  import VisibilityOff from '@mui/icons-material/VisibilityOff';
  import PhoneInput from 'react-phone-input-2';
  import { CheckIcon, CrossIcon } from "./assets";
  import 'react-phone-input-2/lib/material.css';
  import TermsConditions from "../../termsconditions/src/TermsConditions.web";
  import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
 
const validateDateFormat = (dateStr:any) => {
  
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    if (!regex.test(dateStr)) return false;
  
    const [day, month, year] = dateStr.split('-').map(Number);
  
    const date = new Date(year, month - 1, day);
    return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().required('You need to write your name'),
    username: Yup.string().required('Username is required'),
    dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .test('valid-date', 'Invalid date of birth format (ex:- 01-01-2000)', (value) => validateDateFormat(value)),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    gender: Yup.string().required('Gender is required'),
    agreeToTerms: Yup.bool().oneOf([true], 'You must agree to the terms and conditions'),
  });


 
const hasUppercase = (password: string) => /[A-Z]/.test(password);
const hasLowercase = (password: string) => /[a-z]/.test(password);
const hasNumber = (password: string) => /\d/.test(password);
const hasMinLength = (password: string) => password.length >= 8;
 
function renderFormHelperText(error:any, touched:any, stateError:any) {
  const errorMessage = (error && touched && error) || stateError;

  if (errorMessage) {
      return (
          <FormHelperText
              style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: "#DC2626",
                  marginLeft: "0px"
              }}
          >
              {errorMessage}
          </FormHelperText>
      );
  }
  return null;
}


// Customizable Area End

const configJSON = require("./config.js");

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  static EmailAccountRegistration: EmailAccountRegistration;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToastMessage=()=> {
    const { showToastMessage } = this.state;
    if (typeof showToastMessage === 'string') {
      return showToastMessage;
    } else {
      return "Invalid message";
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     <MainWrapper>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0}
           height="100vh">
             {(this.state.termConditionOpen)&&<TermsConditions  type="User" category="Term_and_Conditions" id="" navigation={this.props.navigation}>
             </TermsConditions>}
             {(this.state.privacyPolicyOpen) &&<PrivacyPolicy id="" type="User" category="Term_and_Conditions" navigation={this.props.navigation}>
             </PrivacyPolicy>}
          <Snackbar
  open={this.state.showToast}
  autoHideDuration={6000}
  onClose={this.handleCloseToast}
  anchorOrigin={{ vertical: 'top', 
  horizontal: 'center' }}
  sx={{ top: '0px', justifyContent: 'center' }}
>
<Alert
  onClose={this.handleCloseToast} 
  sx={{ width: '100%' }}
  severity="error"
>
  
  {this.getToastMessage()}
</Alert>

</Snackbar>
       
            <Grid item xs={12} sm={5} className="grid_left" height="100%">
                <Box className="main_box_left" style={{ position: 'relative' }}>
                    <img src={this.state.leftBannerImg} alt="banner" style={{ width: '100%', height:'100%' }} />
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <img src={this.state.logo} alt="logo" style={{ width: '100%', height: '100%', objectFit:'cover' }} />
                    </Box>
                </Box>
            </Grid>
            <Grid
            item
            xs={12}
            sm={7}
            sx={{
              height: '100%',
              display: 'block',
              overflowY: 'auto',
                paddingTop: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingBottom: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingLeft: { xs: '16px', sm: '32px', md: '64px', lg: '137px' },
                paddingRight: { xs: '16px', sm: '32px', md: '64px', lg: '120px' },
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
          >
           
            <Typography className="mainTitle" gutterBottom>
              Get started with Vatrina!
            </Typography>

         
            <Formik
              data-test-id = "formik"
              initialValues={{
                fullName: '',
                username: '',
                dateOfBirth: '',
                email: '',
                phoneNumber: '',
                password: '',
                gender: '',
                agreeToTerms: false,
                countryCode :'49'
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const { password } = values;
                
                if(this.state.termChecked && this.state.policyChecked){
               
                if (hasNumber(password) && hasLowercase(password) && hasUppercase(password) && hasMinLength(password)) {
                  this.handleSignupData(values);
                } else {
                  this.setState({showToastMessage:'Password does not meet the criteria',showToast:true})
                }
              }else{
                this.handleTermsPolicyUser();

              }
              }}
            >
              {({ handleChange, handleBlur, values, errors, touched, setFieldValue,setFieldTouched }) => (
                <Form>
                  <FormControl fullWidth margin="normal">
                    <StyledFormLabel htmlFor="fullName">Full Name</StyledFormLabel>
                    <StyledField
                      as={TextField}
                      id="fullName"
                      name="fullName"
                      data-test-id="fullName"
                      placeholder="Enter your first and last name"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.fullName) && touched.fullName}
                      InputLabelProps={{ shrink: true }}
                    />
                  
                     {renderFormHelperText(errors.fullName, touched.fullName, null)}
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <StyledFormLabel htmlFor="username">Username</StyledFormLabel>
                    <StyledField
                      as={TextField}
                      data-test-id="username"
                      id="username"
                      name="username"
                      placeholder="Choose a unique username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.username) && touched.username}
                      InputLabelProps={{ 
                        shrink: true }}
                     />
                     
 {renderFormHelperText(errors.username, touched.username, this.state.usernameError)}
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <StyledFormLabel htmlFor="dateOfBirth">Date of Birth</StyledFormLabel>
                    <StyledField
                    as={TextField}
                    data-test-id="dateOfBirth"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="text"
                    placeholder="Enter your date of birth"
                    value={values.dateOfBirth}
                    onChange={(e:any) => {
                      const { value } = e.target;
                      const formattedValue = value.replace(/[^0-9-]/g, '');
                      handleChange({
                        target: {
                          name: 'dateOfBirth',
                          value: formattedValue
                        }
                      });
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.dateOfBirth) && touched.dateOfBirth}
                    InputLabelProps={{ shrink: true }}
                  />

                  
                    {renderFormHelperText(errors.dateOfBirth, touched.dateOfBirth, this.state.dobError)}
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                    <StyledFormLabel htmlFor="email">Email</StyledFormLabel>
                    <StyledField
                      as={TextField}
                      data-test-id="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.email) && touched.email}
                      InputLabelProps={{ shrink: true }}
                    />
                     
                   {renderFormHelperText(errors.email, touched.email, this.state.emailError)}
                   </FormControl>
                   <FormControl fullWidth margin="normal">
                      <StyledFormLabel htmlFor="phoneNumber">
                        Phone Number (optional)
                      </StyledFormLabel>
                      <PhoneInput
                        inputProps={{
                          id: "phoneNumber",
                          name: "phoneNumber",
                          "data-test-id": "phoneNumber",
                        }}
                        data-test-id="phoneNumber"
                        placeholder="Phone number"
                        country={"de"}
                        value={values.phoneNumber}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("phoneNumber", value);
                          setFieldValue("countryCode", values.countryCode);
                        }}
                        enableSearch={true}
                        disableSearchIcon={false}
                        countryCodeEditable={true}
                        containerStyle={{ marginTop: "8px", width: "100%" }}
                        inputStyle={{
                          position: "relative",
                          height: "56px",
                          display: "flex",
                          padding: "24px 8px",
                          alignItems: "center",
                          flexShrink: 0,
                          borderRadius: "8px",
                          background: theme.palette.common.white,
                          color: "#94A3B8",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "26px",
                          fontFamily: "Inter"
                        }}
                      />
                    </FormControl>
<FormControl fullWidth margin="normal">
  <StyledFormLabel htmlFor="password">
    Password
  </StyledFormLabel>
  <StyledField
    as={TextField}
    data-test-id="password"
    id="password"
    name="password"
    type={this.state.showPassword ? 'text' : 'password'}
    placeholder="Enter your password"
    value={values.password}
    onChange={(event:any) => {
        this.handlePasswordChange(event);
        handleChange(event)
       
      }}
    onBlur={handleBlur}
    error={Boolean(errors.password) && touched.password}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            data-test-id="togglePassword"
            aria-label="toggle password visibility"
            onClick={this.handleClickShowPassword}
            onMouseDown={this.handleMouseDownPassword}
          >
            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }}
 
  />
 
                    {renderFormHelperText(errors.password, touched.password, null)}
</FormControl>


         {this.state.showValidationMessage && (
        <div>
          <p className="pass_check_title">Your Password must contain:</p>
          <ul style={{ display: "contents" }}>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasUppercase(this.state.password) ? CheckIcon : CrossIcon} At least one capital letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasLowercase(this.state.password) ? CheckIcon : CrossIcon} At least one lowercase letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasNumber(this.state.password) ? CheckIcon : CrossIcon} At least one number
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasMinLength(this.state.password) ? CheckIcon : CrossIcon} Minimum character length is 8 characters
            </li>
          </ul>
        </div>
      )}

                  <FormControl component="fieldset" margin="normal">
                    <StyledFormLabel>Gender</StyledFormLabel>
                    <StyledFieldRadio
                      data-test-id="gender"
                      as={RadioGroup}
                      aria-label="gender"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                    
                    >
                      <FormControlLabel value="Female" control={<Radio />} label="Female" />
                      <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    </StyledFieldRadio>
                
                     {renderFormHelperText(errors.gender, touched.gender, null)}
                  </FormControl>
<Box className="checkBox_Main_Box">
  <Box>
<FormControlLabel
                      sx={{ display: 'flex', marginRight: '0px' }}
                      data-test-id="agreeToTerms"
                      control={<StyledFieldCheckBox
                        as={Checkbox}
                        data-test-id="agreeToTermsInside"
                        name="agreeToTerms"
                        className="root"
                        disableRipple
                        color="default"
                        checkedIcon={<span className={`icon checkedIcon`} />}
                        icon={<span className="icon" />}
                        inputProps={{
                          "aria-label": "decorative checkbox",
                        }}
                        onClick={() => {
                          this.setRememberMeUser(!this.state.checkedRememberMeUser);
                        } }
                        checked={this.state.checkedRememberMeUser}
                        value={values.agreeToTerms}
                        onChange={handleChange}
                        />} label={undefined}  
 
  
/>
</Box>
<Box>
<span className="text">I agree with <span style={{cursor:'pointer',color:'blue'}} onClick={this.handleOpenPrivacyPolicy} data-test-id="handleOpenPrivacyPolicy"  className="text1">Privacy Policy</span> and <span style={{cursor:'pointer',color:'blue'}} onClick={this.handleOpenTermsAndConditions} data-test-id="handleOpenTermsAndConditions" className="text1">Terms and Conditions</span></span>
</Box>
</Box>

                     {renderFormHelperText(errors.agreeToTerms, touched.agreeToTerms, this.state.termsPolicyError)}

                  <StyledFieldButton data-test-id="handleSignupData" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
                    Sign Up
                  </StyledFieldButton>

                  <Typography className="bottomTitle" variant="body2" align="center" sx={{ mt: 2 }}>
                    Already a Member? <span onClick={this.navigateToLogin} data-test-id="navigateToLogin" className="loginButton">Login</span>
                  </Typography>
                </Form>
              )}
            </Formik>
          </Grid>

          </Grid>
        </ThemeProvider>
   </MainWrapper>
     
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .mainTitle": {
      color:'#000000',
      fontFamily: 'Inter',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '56px',
      letterSpacing: '-0.015em',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
          fontSize: '36px',
          lineHeight: '44px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '28px',
          lineHeight: '36px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '24px',
          lineHeight: '32px',
      },
  },
  "& .bottomTitle": {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      color:"#1E293B",
      [theme.breakpoints.down('md')]: {
          fontSize: '18px',
          lineHeight: '24px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
          lineHeight: '22px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
          lineHeight: '20px',
      },
  },
  "& .loginButton": {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      textAlign: 'left',
      color:"#9E52F6",
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
          fontSize: '18px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
      },
  },
  "& .optional-text": {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      color:"#64748B",
      position: "relative",
      top: "-0.4em",
      marginLeft: '-3px',
      [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
      },
  },
  "& .pass_check_title": {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
      color :'#1E293B',
      listStyle:"none",
      [theme.breakpoints.down('md')]: {
          fontSize: '18px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '14px',
      },
  },
  "& .pass_check": {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      color :'#1E293B',
      listStyle:"none",
      padding: '3px 0px',
      [theme.breakpoints.down('md')]: {
          fontSize: '14px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '10px',
      },
  },
  "& .react-tel-input .special-label": {
      display:'none'
  },
  "& .react-tel-input .form-control": {
      display: 'none',
      width: 'calc(100% - 46px)',
      marginLeft: '46px'
  },
  "& .react-tel-input .selected-flag .flag": {
      marginTop:"-10px",
      marginLeft:'-10px'
  },
  "& .checkBox_Main_Box": {
      display:'flex',
      flexDirection: 'row',
      gap: '8px',
      [theme.breakpoints.down('md')]: {
          gap: '4px',
      },
      [theme.breakpoints.down('sm')]: {
          gap: '2px',
      },
      [theme.breakpoints.down('xs')]: {
          gap: '0px',
      },
  },
  "& .text": {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
      color:'#1E293B',
      [theme.breakpoints.down('md')]: {
          fontSize: '16px',
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
      },
  },
  "& .text1": {
      color:'#1E293B',
      fontFamily: 'Inter',
      fontWeight: 700,
      lineHeight: '28px',
  },
}));
const StyledField = styled(Field)(({ theme }) => ({
  borderRadius: "12px",
  "& .MuiInputBase-root": {
    position: "relative",
    width: "100%",
    height: "56px",
    display: "flex",
    padding: "24px 0px",
    alignItems: "center",
    flexShrink: 0,
    background: theme.palette.common.white,
    marginTop: "4px",
    borderRadius:"8px",
    color: '#94A3B8',
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
    [theme.breakpoints.down('md')]: {
      fontSize: "16px",
      height: "48px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      height: "40px",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      height: "32px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding:"8px",
    color:'#475569'
  }
}));

const StyledFieldButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor:"#F1F5F9", 
  color:"#64748B",
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  textTransform:'none',
  boxShadow:'none',
  "&:hover": {
      backgroundColor:'#9E52F6 !important',
      color: "#FFFFFF", 
    },
  "&:active": {
      backgroundColor:'#9E52F6 !important',
      color: "#FFFFFF", 
    },  
  "& .MuiTypography-root": {
    fontFamily: 'Inter !important',
    fontSize: '18px !important',
    fontWeight: '400 !important',
    lineHeight: '26px !important',
    textAlign: 'left !important',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    height: '40px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '14px',
    height: '32px',
  },
}));
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: '#334155',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  '&.Mui-focused': {
    color: '#334155',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));
const StyledFieldRadio = styled(RadioGroup)(({ theme }) => ({
  marginTop: '16px !important',
  '& .MuiFormControlLabel-root': {
    marginTop: '-10px !important',
    '& .MuiTypography-root': {
      marginTop: '3px',
      color: '#0F172A',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '26px',
      textAlign: 'left',
    },
  },
  '& .MuiFormGroup-root': {
    marginTop: '16px !important',
  },
  '& .MuiRadio-root': {
    cursor: 'pointer !important',
    color: '#64748B !important',
    '&.Mui-checked': {
      color: '#9E52F6 !important',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
  },
}));
const StyledFieldCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .icon': {
    marginLeft: '1px',
    marginTop: '-6px',
    border: '1px solid #64748B',
    borderRadius: 6,
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '&.Mui-focusVisible': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  '& .checkedIcon': {
    backgroundColor: '#9E52F6',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: '0px !important',
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .icon': {
      marginLeft: '1px',
      width: 18,
      height: 18,
    },
    '& .checkedIcon': {
      '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .icon': {
      marginLeft: '3px',
      width: 16,
      height: 16,
    },
    '& .checkedIcon': {
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .icon': {
      marginLeft: '3px',
      width: 14,
      height: 14,
    },
    '& .checkedIcon': {
      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        fontSize: '10px',
      },
    },
  },
}));

// Customizable Area End

export default EmailAccountRegistration;

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, leftBannerImg, logo, valid, invalid, GermanFlag, uploadIcon } from "./assets";
import { ChangeEvent } from "react";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  leftBannerImg: string;
  logo: string;
  showPassword: boolean;
  isPasswordValid: boolean,
  showValidationMessage: boolean,
  valid: string;
  invalid: string;
  GermanFlag: string;
  checkedRememberMeUser: boolean;
  checkedRememberMeBrand: boolean;
  phoneNumber: string;
  showToast: boolean;
  showToastMessage: string;
  usernameError: string,
  emailError: string,
  phoneNumberError: string,
  passwordError: string,
  websiteLinkError: string,
  uploadIcon: string,
  termChecked: boolean,
  policyChecked: boolean,
  privacyPolicyOpen: boolean,
  termConditionOpen: boolean,
  termsPolicyError:string,
  dobError:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  createSignUpApiCallId: any = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      leftBannerImg: leftBannerImg.default,
      logo: logo.default,
      showPassword: false,
      isPasswordValid: false,
      showValidationMessage: false,
      valid: valid.default,
      invalid: invalid.default,
      GermanFlag: GermanFlag.default,
      checkedRememberMeUser: false,
      checkedRememberMeBrand: false,
      phoneNumber: "",
      showToast: false,
      showToastMessage: '',
      usernameError: '',
      emailError: '',
      phoneNumberError: '',
      passwordError: '',
      websiteLinkError: '',
      uploadIcon: uploadIcon.default,
      termChecked: false,
      policyChecked: false,

      privacyPolicyOpen: false,
      termConditionOpen: false,
      termsPolicyError:'',
      dobError:''
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          }

        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    this.receive1(message);
    this.receive2(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    localStorage.removeItem("token");
  }
  receive1 = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId === this.createSignUpApiCallId) {
        if (!responseJson.errors) {
          this.SignUpRequestResponse(responseJson);

        } else {
          this.showToast(responseJson)
         
        }
      }
    }
  }

  receive2 = async (message: Message) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const recivemsg = message.getData(getName(MessageEnum.SessionResponseData));
  
      const handleAcceptance = (isTerm: boolean) => {
        if (isTerm) {
          this.setState({ termChecked: true, termsPolicyError: '' });
        } else {
          this.setState({ policyChecked: true, termsPolicyError: '' });
        }
      };
  
      const handleCancellation = (block: string) => {
        const isTermBlock = block === 'term';
        this.setState({
          termChecked: !isTermBlock, 
          policyChecked: isTermBlock,
          termConditionOpen: false,
          privacyPolicyOpen: false
        });
      };
  
      if (recivemsg.terms === "true") {
        handleAcceptance(true);
      } else if (recivemsg.policy === "true") {
        handleAcceptance(false);
      } 
      else if (recivemsg.cancelData === "false") {
        handleCancellation(recivemsg.block);
      }
    }
  };
  

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const isPasswordValid = this.validatePassword(password);

    this.setState({
      password,
      isPasswordValid,
      showValidationMessage: password.length > 0,
    });
  };

  validatePassword = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;

    return hasUppercase && hasLowercase && hasNumber && hasMinLength;
  };

  setRememberMeUser = (value: boolean) => {
    this.setState({ checkedRememberMeUser: value });
  };
  setRememberMeBrand = (value: boolean) => {
    this.setState({ checkedRememberMeBrand: value });
  };
  handleSignupData = (value: any) => {
    const header = {
      token: ""
    };


    const full_phone_number = "+" + value.phoneNumber;
    const user_type = "User";
    const country = "India";
    const data = new FormData();


    data.append('data[type]', 'email_account');
    data.append('data[attributes][full_name]', value.fullName);
    data.append('data[attributes][user_name]', value.username);
    data.append('data[attributes][date_of_birth]', value.dateOfBirth);
    data.append('data[attributes][email]', value.email);
    data.append('data[attributes][password]', value.password);
    data.append('data[attributes][gender]', value.gender);
    data.append('data[attributes][terms_and_conditions]', value.agreeToTerms);
    data.append('data[attributes][user_type]', user_type);
    data.append('data[attributes][profile_attributes][country]', country);
    data.append('data[attributes][phone_number]', full_phone_number);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createSignUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignUpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


  }
  handleBrandSignupData = (value: any) => {
    const header = {
      token: ""
    };


    const full_phone_number = "+" + value.phoneNumber;
    const user_type = "Brand";
    const country = "India";
    const data = new FormData();


    data.append('data[type]', 'email_account');
    data.append('data[attributes][business_name]', value.businessName);
    data.append('data[attributes][user_name]', value.username);
    data.append('data[attributes][email]', value.email);
    data.append('data[attributes][phone_number]', full_phone_number);
    data.append('data[attributes][password]', value.password);
    data.append('data[attributes][terms_and_conditions]', value.agreeToTerms);
    data.append('data[attributes][user_type]', user_type);
    data.append('data[attributes][profile_attributes][country]', country);


    const requestMessageBrand = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createSignUpApiCallId = requestMessageBrand.messageId;
    requestMessageBrand.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignUpApiEndPoint
    );

    requestMessageBrand.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageBrand.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessageBrand.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessageBrand.id, requestMessageBrand);
    return true;


  }

  SignUpRequestResponse = (responseJson: any) => {
    setStorageData("token", responseJson.token)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'OTPInputAuth'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { responseJson })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleCloseToast = () => {
    this.setState({ showToast: false });
  };

  showToast = (responseJson: any) => {
    if (responseJson.errors[0].email) {
      this.setState({ showToastMessage: responseJson.errors[0].email, showToast: true, emailError: responseJson.errors[0].email, usernameError: '', phoneNumberError: '', dobError:'' });
    }
    if (responseJson.errors[0].dob) {
      this.setState({ showToastMessage: responseJson.errors[0].dob, showToast: true, dobError: responseJson.errors[0].dob, usernameError: '', phoneNumberError: '', emailError:'' });
    }
    else if (responseJson.errors[0].phone_number) {
      this.setState({ showToastMessage: responseJson.errors[0].phone_number, showToast: true, phoneNumberError: responseJson.errors[0].phone_number, emailError: '', usernameError: '', dobError:'' });
    } else if (responseJson.errors[0].username) {
      this.setState({ showToastMessage: responseJson.errors[0].username, showToast: true, usernameError: responseJson.errors[0].username, phoneNumberError: '', emailError: '', dobError:'' });
    }
  }


  navigateToLogin = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleOpenPrivacyPolicy = () => {
    this.setState({ privacyPolicyOpen: true });
  }
  handleOpenTermsAndConditions = () => {
    this.setState({ termConditionOpen: true });

  }
handleTermsPolicyUser = () => {
  const { policyChecked, termChecked, checkedRememberMeUser } = this.state;
  this.setState({ termsPolicyError: '' });
  if (checkedRememberMeUser) {
    if (!policyChecked) {
      this.setState({ termsPolicyError: "Please agree to the Privacy Policy" });
    } else if (!termChecked) {
      this.setState({ termsPolicyError: "Please agree to the Terms and Conditions" });
    } else {
      this.setState({ termsPolicyError: "" });
    }
  }
};


handleTermsPolicyBrand = () => {
  const { policyChecked, termChecked, checkedRememberMeBrand } = this.state;
  this.setState({ termsPolicyError: '' });
  if (checkedRememberMeBrand) {
    if (!policyChecked) {
      this.setState({ termsPolicyError: "Please agree to the Privacy Policy" });
    } else if (!termChecked) {
      this.setState({ termsPolicyError: "Please agree to the Terms and Conditions" });
    } else {
      this.setState({ termsPolicyError: "" }); 
    }
  }
};
  // Customizable Area End
}

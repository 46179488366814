import React from "react";
// Customizable Area Start
import {
  AppBar, 
  Toolbar, 
  Typography, 
  ThemeProvider, 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  styled, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Paper, 
  ListItemButton, 
  Divider, 
  Pagination, 
  IconButton,
  Avatar
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
interface Invoice {
  id: number;
  date: string;
  status: string;
  amount: string;
  plan: string;
}

const drawerWidth = 292;


// Customizable Area End

const configJSON = require("./config.js");

import LandingPageController, {
  Props
} from "./LandingPageController";

class LandingPage extends LandingPageController {
  static LandingPage: LandingPage;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const invoices: Invoice[] = [
      { id: 12, date: 'Dec 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 11, date: 'Nov 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 10, date: 'Oct 1, 2023', status: 'Paid', amount: '250 QAR', plan: 'Basic' },
      { id: 9, date: 'Sep 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 8, date: 'Aug 1, 2023', status: 'Paid', amount: '0 QAR', plan: 'Free' },
    ];
    const { currentPage } = this.state;
    const invoicesPerPage = 4;
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
    return (
      <MainWrapper>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <Drawer
            variant={this.state.isMobile<600 ? 'temporary' : 'persistent'} 
            open={this.state.isMobile<600 ? this.state.mobileOpen : true}
            onClose={this.state.isMobile<600 ? this.handleDrawerToggle : undefined}
            sx={{
              width: this.state.isMobile>600 ?drawerWidth:'',
              flexShrink: this.state.isMobile>600 ?0:'',
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: this.state.isMobile>600 ?'border-box':'' },
            }}
          >
          <Box sx={{ textAlign: 'center', marginTop: 3, marginBottom: 2 }}>
            <img
              src={this.state.logo}
              alt="logo"
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={this.state.isMobile<600 ? this.handleDrawerToggle : undefined}
            />
          </Box>

          <Box sx={{ overflow: 'auto', flexGrow: 1, marginTop: '154px' }}>
            <List>
              <ListItemButton data-test-id="navigateDrawerHome" onClick={()=>{this.navigateDrawer("Home")}}>
                <CustomListItemIcon>
                  <img src={this.state.homeIconActive} alt="home icon" />
                </CustomListItemIcon>
                <CustomListItemText secondary="Home" />
              </ListItemButton>
              <ListItemButton data-test-id="navigateDrawerSubscription" onClick={()=>{this.navigateDrawer("Subscription")}}>
                <CustomListItemIcon>
                  <img src={this.state.subscriptionIcon} alt="subscription icon" />
                </CustomListItemIcon>
                <CustomListItemText primary="Subscription Settings" />
              </ListItemButton>
              <ListItemButton data-test-id="navigateDrawerInvoice" onClick={()=>{this.navigateDrawer("Invoice")}}>
                <CustomListItemIcon>
                  <img src={this.state.invoiceIcon} alt="invoice icon" />
                </CustomListItemIcon>
                <CustomListItemText primary="Invoices" />
              </ListItemButton>
            </List>
          </Box>

          <Box sx={{ paddingBottom: 2 }}>
            <List>
              <ListItemButton data-test-id="handleLogout" onClick ={()=>{this.handleLogout()}}>
                <CustomListItemIcon>
                  <img src={this.state.logoutIcon} alt="logout icon" />
                </CustomListItemIcon>
                <CustomListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
          

           {this.state.drawerState =="Home" && <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>

              <AppBar position="sticky" sx={{ bgcolor: '#fff', color: '#000', boxShadow: 'none', borderBottom: '1px solid #E2E8F0' }}>
                <CustomToolbar>
                {this.state.isMobile<600 && (
                   <IconButton data-test-id="menuButton" onClick={this.handleDrawerToggle} sx={{ margin: 1, alignItems:'start', marginTop:'10px' }}>
                     <MenuIcon />
                   </IconButton>
                )}
                  <Typography className="homeTitleTypo" noWrap component="div">
                    Home
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit">
                      <LanguageIcon />
                    </IconButton>
                    <IconButton color="inherit">
                    <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/3.jpg"
                    sx={{ width: 24, height: 24 }}
                    />
                    </IconButton>
                  </Box>
                </CustomToolbar>
              </AppBar>

              <Box sx={{ padding: 3 }}>
                <Typography className="MainTitleTypo" gutterBottom>Overview</Typography>
                <Typography className="SecondTitleTypo" gutterBottom>Manage your subscription billing</Typography>
                <CustomPaper  className="upperPaper">

                  <Box sx={{ marginBottom: "-4px" }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between'
                    ,[theme.breakpoints.down(700)]:{
                      justifyContent: 'none',
                      flexDirection:'column'

                    }
                   }}>
                    <Typography className="MainPaperTitleTypo"  gutterBottom>Your current Plan</Typography>
                    <Typography className="SecondPaperTitleTypoLeft">500 QAR <span className="SecondPaperTitleTypoLeftSpan">/ month</span></Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography className="SecondPaperTitleTypo" color="primary">Advanced Plan</Typography>
                    </Box>
                    <Divider sx={{marginTop:2,marginBottom:1}}/>
                    <List>
                      <CustomListItem>
                        <Typography className="featureTypo">Features</Typography>
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="All benefits from basic tier" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="Unlimited Livestream Views" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="4 Live Events/Month" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="60 Min Live Duration" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIconLast" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="Additional Yearly Credit (500 QR), 5% Discount on Top-Ups" />
                      </CustomListItem>
                    </List>
                    <Divider sx={{marginTop:'5px',marginBottom:2}}/>
                    <Typography className="upcomingInvoiceTypo">Upcoming invoice</Typography>
                    <Typography className="dateTypo">1 Jan 2024</Typography>
                  </Box>
                </CustomPaper>
                <Typography className="MainTitleTypo" gutterBottom>Invoices</Typography>
                <Typography className="SecondTitleTypo" gutterBottom>Access all your previous invoices</Typography>
                <Box sx={{display: 'grid',overflowX: 'scroll'}}>
                <CustomTableContainer>
                  <Table>
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCellHeader>Invoice</CustomTableCellHeader>
                        <CustomTableCellHeader>Date</CustomTableCellHeader>
                        <CustomTableCellHeader>Status</CustomTableCellHeader>
                        <CustomTableCellHeader>Amount</CustomTableCellHeader>
                        <CustomTableCellHeader>Plan</CustomTableCellHeader>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {currentInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <CustomTableCellData>{`Invoice #${invoice.id} / ${invoice.date.split(' ')[0]} ${invoice.date.split(' ')[2]}`}</CustomTableCellData>
                          <CustomTableCellData>{invoice.date}</CustomTableCellData>
                          <CustomTableCellData>{invoice.status}</CustomTableCellData>
                          <CustomTableCellData>{invoice.amount}</CustomTableCellData>
                          <CustomTableCellData>{invoice.plan}</CustomTableCellData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box className='paginationBox'>
                    <CustomPagination
                      count={Math.ceil(invoices.length / invoicesPerPage)}
                      page={currentPage}
                      onChange={this.handlePageChange}
                      data-test-id="handlePageChange"
                    />
                  </Box>
                </CustomTableContainer>
                </Box>
                

              </Box>
            </Box>}
            {this.state.drawerState =="Invoice" && <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>

              <AppBar position="sticky" sx={{ bgcolor: '#fff', color: '#000', boxShadow: 'none', borderBottom: '1px solid #E2E8F0' }}>
                <CustomToolbar>
                {this.state.isMobile<600 && (
                   <IconButton data-test-id="menuButton" onClick={this.handleDrawerToggle} sx={{ margin: 1, alignItems:'start', marginTop:'10px' }}>
                     <MenuIcon />
                   </IconButton>
                )}
                  <Typography className="homeTitleTypo" noWrap component="div">
                  Invoices
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit">
                      <LanguageIcon />
                    </IconButton>
                    <IconButton color="inherit">
                    <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/3.jpg"
                    sx={{ width: 24, height: 24 }}
                    />
                    </IconButton>
                  </Box>
                </CustomToolbar>
              </AppBar>

              <Box
               sx={{ padding: 3 }}>
                <Typography
                 className="MainTitleTypo" 
                 gutterBottom>Invoices
                 </Typography>
                <Typography 
                className="SecondTitleTypo" 
                gutterBottom>Access all your previous invoices
                </Typography>
                <Box 
                sx={{display: 'grid',
                overflowX: 'scroll'}}>
                  <CustomTableContainer>  


                  <Table><TableHead>
                       <CustomTableRow>
                        <CustomTableCellHeader>
                          Invoice
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Date
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Status
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Amount
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Plan
                        </CustomTableCellHeader>
                      </CustomTableRow></TableHead>
                    <TableBody>
                       {currentInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <CustomTableCellData>
                            {`Invoice #${invoice.id} / ${invoice.date.split(' ')[0]} ${invoice.date.split(' ')[2]}`}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.date}
                          </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.status}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.amount}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.plan}
                            </CustomTableCellData>
                          </TableRow>))}</TableBody>
                  </Table>
                  <Box 
                  className='paginationBox'>
                    <CustomPagination count={Math.ceil(invoices.length / invoicesPerPage)}
                    page={currentPage} onChange={this.handlePageChange}
                      data-test-id="handlePageChange"/></Box>
                </CustomTableContainer></Box></Box></Box>}</Box>
        </ThemeProvider>
      </MainWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .homeTitleTypo": {
    display: 'flex',
    alignItems: 'center',
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }
  },
  "& .upperPaper": {
    padding: '16px',
    marginBottom: '42px',
    marginTop: '45px',
    [theme.breakpoints.down('md')]: { 
      padding: '14px',
      marginBottom: '35px',
      marginTop: '40px',
    },
    [theme.breakpoints.down('sm')]: { 
      padding: '12px',
      marginBottom: '30px',
      marginTop: '35px',
    }
  },
  "& .MainTitleTypo": {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }
  },
  "& .SecondTitleTypo": {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    letterSpacing: '-0.005em',textAlign: 'left',
     [theme.breakpoints.down('md')]: { 
      fontSize: '1.375rem',lineHeight: '1.875rem',
    },
     [theme.breakpoints.down('sm')]: { 
      fontSize: '1.25rem',lineHeight: '1.75rem',
    }},
  "& .MainPaperTitleTypo": {
    color: '#1E293B',fontFamily: 'Inter',
    fontSize: '1.125rem',fontWeight: 400,
    lineHeight: '1.625rem',textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1rem',lineHeight: '1.5rem',
     },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '0.875rem', lineHeight: '1.25rem',
  }
   },
    "& .SecondPaperTitleTypo": {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '2rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.375rem',
      lineHeight: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    }
  },
   "& .SecondPaperTitleTypoLeft": {color: '#1E293B',fontFamily: 'Inter',
           fontSize: '1.25rem',fontWeight: 700,lineHeight: '1.75rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1.125rem',lineHeight: '1.625rem',
     },
     [theme.breakpoints.down('sm')]: { fontSize: '1rem',lineHeight: '1.5rem',}
   },"& .SecondPaperTitleTypoLeftSpan": {
    color: '#1E293B',fontFamily: 'Inter',fontSize: '1.25rem',fontWeight: 400,
    lineHeight: '1.75rem',textAlign: 'left',
    [theme.breakpoints.down('md')]: { fontSize: '1.125rem',lineHeight: '1.625rem',
    },
    [theme.breakpoints.down('sm')]: { fontSize: '1rem',lineHeight: '1.5rem',
     }
   }, "& .featureTypo": {
    marginBottom: '10px',color: '#1E293B',fontFamily: 'Inter',fontSize: '1.125rem',
    fontWeight: 700,lineHeight: '1.625rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1rem',lineHeight: '1.5rem',
    },
     [theme.breakpoints.down('sm')]: { fontSize: '0.875rem',lineHeight: '1.25rem',
     }},"& .upcomingInvoiceTypo": {
    color: '#1E293B',fontFamily: 'Inter',fontSize: '1.125rem',fontWeight: 400,
    lineHeight: '1.625rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1rem',lineHeight: '1.5rem',
     },
     [theme.breakpoints.down('sm')]: { fontSize: '0.875rem',lineHeight: '1.25rem',
     }},"& .dateTypo": {
    marginTop: '5px',
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1rem',
      lineHeight: '1.5rem',
    }
  },
  "& .checkedIcon": {
    marginRight: "8px",
    marginTop: '-5px'
  },
  "& .checkedIconLast": {
    marginRight: "8px",
    marginTop: '-5px',
    [theme.breakpoints.down(527)]:{
      marginTop:'-28px'
    }
  },
  "& .paginationBox": {
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { 
      marginTop: '10px',
      marginBottom: '10px',
    }
  }
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: '92px !important',
  [theme.breakpoints.down('md')]: { 
    minHeight: '82px !important',
  },
  [theme.breakpoints.down('sm')]: { 
    minHeight: '72px !important',
  }
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  padding: '16px',
  [theme.breakpoints.down('md')]: { 
    padding: '14px',
  },
  [theme.breakpoints.down('sm')]: { 
    padding: '12px',
  }
}));

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  marginTop: '50px',
  width:'auto',
  [theme.breakpoints.down('md')]: { 
    marginTop: '40px',
  },
  [theme.breakpoints.down('sm')]: { 
    marginTop: '30px',
  },
  
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: '3px 0px',
  "& .MuiListItem-root": {
    padding: '3px 0px'
  }
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#E2E8F0',
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    cursor:'pointer',
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: { 
      fontSize: '14px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
  '& .MuiListItemText-secondary': {
    cursor:'pointer',
    color: '#6200EA',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: { 
      fontSize: '14px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
}));

const CustomListItemTextFeature = styled(ListItemText)(({ theme }) => ({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: { 
    fontSize: '17px',
    lineHeight: '24px',
  },
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
    lineHeight: '22px',
  }
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '32px',
  marginTop: '-4px',
  color: '#64748B'
}));

const CustomTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#64748B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: { 
    fontSize: '18px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

const CustomTableCellData = styled(TableCell)(({ theme }) => ({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '28px',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: { 
    fontSize: '18px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'center',
    minWidth: '32px',
    height: '32px',
    borderRadius: '4px',
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: '#6200EA',
    fontWeight: 700,
    backgroundColor: 'transparent',
  },
  "& .MuiPaginationItem-root:hover": {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  "& .MuiPaginationItem-ellipsis": {
    color: '#0F172A',
  },
  "& .MuiPaginationItem-icon": {
    color: '#0F172A',
    fontSize: '18px',
  },
}));


// Customizable Area End

export default LandingPage;
